<template>
	<div>
		<div class="grid grid-cols-2 mb-4">
			<div class="col-span-1">
				<h2 class="text-2xl font-bold">
					{{ company.name | sentenceCase }}
					<span class="font-light">Employees</span>
				</h2>
			</div>
			<div class="col-span-1">
				<div class="relative">
					<input
						type="search"
						name="search"
						class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-xs w-full"
						placeholder="Search for employee’s name, status, etc."
						v-model="searchQuery"
					/>
					<ion-icon
						name="search-outline"
						class="text-blue-200 absolute z-10 text-lg ml-3 left-0"
						style="top: 50%; transform: translateY(-50%);"
					></ion-icon>
				</div>
			</div>
		</div>

		<div class="border border-solid border-blue-200 rounded overflow-hidden">
			<div class="flex">
				<div
					class="duration-300"
					:class="{ 'w-full': !isOpen, 'w-1/2': isOpen }"
				>
          <datatable
              ref="table"
              :className="tableRowClassName"
              :columns="columns"
              :data="employees.users"
              :index="true"
              :loading="loading"
              :onClick="click"
              :query="searchQuery"
              :reverse-index="true"/>
				</div>
				<div class="w-1/2 px-8 py-10 border-l border-blue-200" v-if="isOpen">
					<div class="h-full overflow-y-auto">
						<div class="animated animation-delay-300 fadeIn">
							<div class="flex flex-row items-center justify-between mb-8">
								<div class="flex flex-row items-baseline">
									<h2 class="text-lg font-bold mr-2">
										{{ employee.name | sentenceCase }}
									</h2>
									<span class="font-hairline text-xs text-gray-500">{{
										position | sentenceCase
									}}</span>
								</div>
								<div class="flex flex-col ml-3">
									<div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
									<div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
									<div class="h-1 w-1 bg-gray-400 rounded-full"></div>
								</div>
							</div>

							<div class="mb-10 text-right">
								<router-link
									class="btn btn-blue-outline"
									:to="{ name: 'staff-view', params: { staffId: employee.id } }"
								>
									View Profile
								</router-link>
							</div>

							<div
								class="grid grid-cols-1 xl:grid-cols-2 border-blue-200 border mb-8"
							>
								<div class="col-span-1">
									<div class="px-8 xl:px-12 py-8 border-blue-200 relative">
										<span
											class="absolute h-4 bg-green-500 block left-0 ml-3 xl:ml-5"
											style="width: 3px"
										></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Salary</div>
											<div
												class="flex items-center justify-center w-6 h-6 border-2 border-solid border-green-500 text-green-500 rounded-full font-black"
											>
												₦
											</div>
										</div>
										<div class="text-xs font-medium">
											₦
											{{
												employee.profile
													? employee.profile.salary
													: 0 | currency
											}}
										</div>
									</div>
								</div>

								<div class="col-span-1">
									<div
										class="px-8 xl:px-12 py-8 border-t xl:border-t-0 xl:border-l border-blue-200 relative"
									>
										<span
											class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5"
											style="width: 3px"
										></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Position</div>
											<div
												class="w-6 h-6 flex items-center justify-center border border-solid border-blue-500 text-blue-500 rounded-full font-black text-sm"
											>
												<ion-icon name="briefcase-outline"></ion-icon>
											</div>
										</div>
										<div class="text-xs font-medium">
											{{ employee.profile ? employee.profile.position : "---" }}
										</div>
									</div>
								</div>

								<div class="col-span-1">
									<div
										class="px-8 xl:px-12 py-8 border-t border-blue-200 relative"
									>
										<span
											class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5"
											style="width: 3px"
										></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Phone Number</div>
											<div
												class="w-6 h-6 flex items-center justify-center border border-solid border-blue-500 text-blue-500 rounded-full font-black text-sm"
											>
												<ion-icon name="call-outline"></ion-icon>
											</div>
										</div>
										<div class="text-xs font-medium">
											{{ employee.phone_no }}
										</div>
									</div>
								</div>

								<div class="col-span-1">
									<div
										class="px-8 xl:px-12 py-8 border-t xl:border-l border-blue-200 relative"
									>
										<span
											class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5"
											style="width: 3px"
										></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Email</div>
											<div
												class="w-6 h-6 flex items-center justify-center border border-solid border-blue-500 text-blue-500 rounded-full font-black text-sm"
											>
												<ion-icon name="mail-outline"></ion-icon>
											</div>
										</div>
										<div class="text-xs font-medium">{{ employee.email }}</div>
									</div>
								</div>

								<div class="col-span-1">
									<div
										class="px-8 xl:px-12 py-8 border-t border-blue-200 relative"
									>
										<span
											class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5"
											style="width: 3px"
										></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Employment Type</div>
											<div
												class="w-6 h-6 flex items-center justify-center border border-solid border-blue-500 text-blue-500 rounded-full font-black text-sm"
											>
												<ion-icon name="briefcase-outline"></ion-icon>
											</div>
										</div>
										<div class="text-xs font-medium">
											{{ employee.profile ? employee.profile.emp_type : "---" }}
										</div>
									</div>
								</div>

								<div class="col-span-1">
									<div
										class="px-8 xl:px-12 py-8 border-t xl:border-l border-blue-200 relative"
									>
										<span
											class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5"
											style="width: 3px"
										></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Loan Facility</div>
											<div
												class="w-6 h-6 flex items-center justify-center border border-solid border-blue-500 text-blue-500 rounded-full font-black text-sm"
											>
												<ion-icon name="calculator-outline"></ion-icon>
											</div>
										</div>
										<div class="text-xs font-medium">
											{{
												employee.profile
													? employee.profile.current_loan_facility
													: "---"
											}}
										</div>
									</div>
								</div>

								<div class="col-span-1">
									<div
										class="px-8 xl:px-12 py-8 border-t border-blue-200 relative"
									>
										<span
											class="absolute h-4 bg-green-500 block left-0 ml-3 xl:ml-5"
											style="width: 3px"
										></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Total Loan Amount</div>
										</div>
										<div class="text-xs font-medium">
											₦ {{ totalLoanAmount | currency }}
										</div>
									</div>
								</div>

								<div class="col-span-1">
									<div
										class="px-8 xl:px-12 py-8 border-t xl:border-l border-blue-200 relative"
									>
										<span
											class="absolute h-4 bg-orange-500 block left-0 ml-3 xl:ml-5"
											style="width: 3px"
										></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Current Loan</div>
										</div>
										<div class="text-xs font-medium">₦ 0.00</div>
									</div>
								</div>
							</div>

							<h2 class="text-lg font-bold mb-5">Recent Transactions</h2>

							<div class="border border-solid border-blue-200">
								<datatable :data="transactions" :columns="transactionColumns" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapState} from "vuex";

export default {
	data() {
		return {
			searchQuery: "",
			company: {
        name: ''
      },
			employee: null,
			employees: [],
			loading: null,
			columns: [
				{
					name: "name",
					th: "Staff Name",
				},
				{
					name: "email",
					th: "Email Address",
				},
				{
					name: "loan",
					th: "Total Loans",
					render: (employee, loans) => loans.length,
				},
				{
					name: "status",
					th: "Profile Approval",
					render: (employee) => {
						if (!employee.profile && !employee.roles == "company_hr") {
							return `<div class="badge badge-orange-soft-outline">
									Not Updated
								</div>`;
						}

						if (employee.profile?.status || employee.roles == "company_hr") {
							return `<div class="badge badge-green-soft-outline">
									${employee.roles == "company_hr" ? "Company HR" : "Approved"}
								</div>`;
						}

						return `<div class="badge badge-gray-soft-outline">
								Pending
							</div>`;
					},
				},
				{
					name: "bank_account_number",
					th: "Bank Account Number",
					render: (employee) => employee.profile?.account_no,
				},
				{
					name: "bank_name",
					th: "Bank Name",
					render: (employee) =>
						this.$options.filters.bankName(
							employee.profile?.bank_name,
							this.banks
						),
				},
			],
			transactionColumns: [
				{
					name: "created_at",
					th: "Time",
					render: (transaction, created_at) => {
						return (
							this.$options.filters.dateFormat(created_at, "M dd") +
							" at " +
							this.$options.filters.dateFormat(created_at, "h:ia")
						);
					},
				},
				{
					name: "status",
					th: "Status",
					render: (transaction, status) => {
						switch (status) {
							case "approved":
								return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Confirmed
									</div>`;
							case "declined":
								return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
										Pending
									</div>`;
							case "paid":
								return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Confirmed
									</div>`;
							case "pending":
							default:
								return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
										Pending
									</div>`;
						}
					},
				},
				{
					align: "right",
					name: "loan_amount",
					th: "Amount",
					render: (transaction, amount) =>
						`₦ ${this.$options.filters.currency(amount)}`,
				},
			],
		};
	},
	computed: {
		...mapState(["banks"]),

		company_id() {
			return this.$route.params.company_id;
		},
		company_name() {
			return this.$route.query.company_name;
		},
		isOpen() {
			return !!this.employee;
		},
		position() {
			return this.employee?.profile?.position;
		},
		totalLoanAmount() {
			return (
				this.transactions &&
				this.transactions.reduce(
					(last, transaction) => last + Number(transaction.loan_amount),
					0
				)
			);
		},
		transactions() {
			return this.employee?.loan;
		},
	},
	beforeMount() {
    this.fetchData()
	},
	methods: {
		click(employee) {
			this.employee = this.employee === employee ? null : employee;
			this.$refs.table.renderData();
		},
		tableRowClassName(row) {
			return row.id === this.employee?.id ? "selected" : "";
		},
    fetchData() {
      Promise.resolve(() => this.loading = true)
          .then(this.getCompany)
          .then(this.getStaff)
          .finally(() => this.loading = false)
    },
    getCompany() {
      return this.$get({
        url: `${this.$baseurl}/companies/${this.company_id}`,
        headers: this.headers,
        success: response => {
          this.company = response.data.company;
        }
      });
    },
    getStaff() {
      return this.$get({
        url: `${this.$baseurl}/companies/staff-hr/${this.company_id}`,
        headers: this.headers,
        success: response => {
          this.employees = response.data.data;
          this.employees.hr.forEach((el, i, arr) => {
            el.roles.forEach((el) => {
              arr[i].roles = el.slug;
            });
          });
          this.employees.hr.forEach((element) => {
            this.employees.users.unshift(element);
          });
        }
      })
    }
	},
};
</script>